$(document).ready(function() {
	/*
		Match the banner height to the scene height
	*/
	// Default.
	let windowWidth = $(window).outerWidth();
	let bannerHeight = 980;

	if( windowWidth < 768  ){
		bannerHeight = 700;
	}

	// Check in case it needs more space.
	let sceneHeight = $( '#scenes' ).outerHeight() + 30;

	// Needs check as this js file is also loaded on the home template.
	if ( $( '#scenes' ).length > 0 && windowWidth > 767 ) {
		if ( sceneHeight > bannerHeight ) {
			$( '.banner' ).css('height', sceneHeight);
		} else {
			$( '.banner' ).css('height', bannerHeight);
		}
	}



	// Init
	let circleRed      = $('.circle-red');
	let circleProgress = 0;
	let dotsNumber     = 0;

	if ( $('[data-scenes-number]').data() ){
		dotsNumber = $('[data-scenes-number]').data().scenesNumber;
	}


	let circleProgressValues = [ 0, 18.5, 38, 61, 76.8, 100 ];
	if ( dotsNumber === 7 ){
		circleProgressValues = [ 0, 12, 27, 45, 57, 67, 85, 100 ];
	} else if ( dotsNumber === 6 ) {
		circleProgressValues = [ 0, 15, 35, 51, 67, 85, 100 ];
	} else if ( dotsNumber === 12 ) {
		// The timeline.
		circleProgressValues = [ 0, 8, 15, 24, 32, 41, 49, 57, 65, 73, 81, 90, 100 ];
	}

	// Timelines
	let circleTL = new TimelineMax();
	circleTL.to( circleRed, 0,{ drawSVG:circleProgress+'%', ease:Power4.easeNone } );  // Circle to starting point.

	$( '#scenes .scene-0' ).addClass( 'active' ); // active the default scene

	/**
	 * Add text to dots
	 */
	$( '.dot.dot-red' ).each(function( index ){

		$( '#circle-titles .title-' + index )
			.css( 'position', 'absolute' )
			.css( 'z-index', '91' );

		if ( windowWidth > 991 ){

			let titleWidth = $( '#circle-titles .title-' + index ).width();
			let top  = $( this ).position().top - 50;
			let left = $( this ).position().left - titleWidth - 30;
	
			// Dot 2 and 3
			if ( index === 2 || index == 3 ) { 
				left = $( this ).position().left + 30;
			}
			// Dot 4
			if ( index === 4 ) {
				top = $( this ).position().top - 20;
	
				if ( dotsNumber === 6 || dotsNumber === 7 ) {
					left = $( this ).position().left;
				} else {
					left = $( this ).position().left - titleWidth / 2;
				}
			}
			// Dot 5
			if ( index === 5 ) {
				if ( dotsNumber === 7 ) {
					top = $( this ).position().top - 20;
					left = $( this ).position().left - 10;
				} 
			}


			// TIMELINE
			if ( dotsNumber === 12 ){
				// Dot 0, 1 and 2
				if ( index === 0 || index=== 1 || index == 2 ) {
					top  = $( this ).position().top - 50;
					left = $( this ).position().left + 30;
				}
				// Dot 4
				if ( index === 4 ) { 
					top  = $( this ).position().top - 20;
					left = $( this ).position().left + 20;
				}
				// Dot  5
				if ( index=== 5 ) { 
					top  = $( this ).position().top - 20;
					left = $( this ).position().left - titleWidth / 2;
				}
			}


	
			$( '#circle-titles .title-' + index )
				.css( 'top', top )
				.css( 'left', left );

		} else {

			let titleWidth = $( '#circle-titles .title-' + index ).width();
			let top  = $( this ).position().top - 80;
			let left = $( this ).position().left - titleWidth - 30;
	
			// Dot 2 and 3
			if ( index === 2 || index == 3 ) {
				left = $( this ).position().left + 30;
			}
			// Dot 4
			if ( index === 4 ) {
				top = $( this ).position().top - 30;
	
				if ( dotsNumber === 6 || dotsNumber === 7 ) {
					left = $( this ).position().left;
				} else {
					left = $( this ).position().left - titleWidth / 2;
				}
			}
			// Dot 5
			if ( index === 5 ) {
				if ( dotsNumber === 7 ) {
					top = $( this ).position().top - 20;
					left = $( this ).position().left - 10;
				} 
			}

			$( '#circle-titles .title-' + index )
				.css( 'top', top )
				.css( 'left', left );

		}

	});

	// Text align - depending on the positioning and dots number (dotsNumber).
	var dotsList = $("#circle-titles .title");

	dotsList = dotsList.slice( 0, Math.floor( dotsList.length/2 ) );

	dotsList.each(function(){
		$( this ).css('text-align', 'right');
	});


	// First dot animation - start.
	let startAnim = new TimelineMax();
	startAnim.fromTo('.dot-red.dot--0', .8, {scale: 1}, {scale: 1.5, repeat: -1, repeatDelay:0, yoyo: true, transformOrigin: 'center center'});
	startAnim.fromTo('.dot-red.dot--0', .8, {fill: '#EE2737'}, {fill: '#b30b19', repeat: -1, repeatDelay:0, yoyo: true});


	// When the user clicks on the dots.
	$('.dot, #circle-titles .title').click(function() {

		startAnim.progress(1).kill();

		let thisDotNum    = $(this).index();
		let lastActiveDot = $('.dot-red.active:last').index();

		//console.log('thisDotNum: ' + thisDotNum);
		//console.log('lastActiveDot: ' +  lastActiveDot );
		//console.log('---------------------------------');

		circleProgress = circleProgressValues[ thisDotNum ];

		// Move the circle
		circleTL.to(circleRed, 1.2,{drawSVG:circleProgress+'%', ease: Power4.easeNone});

		// update last active dot
		lastActiveDot = thisDotNum;
		
		setTimeout(() => {
			// Update active dot and title
			$( '.dot-red, #circle-titles .title' ).removeClass( 'active' ); // reset
			$( '.dot-red' ).attr('r', '8');              // reset dot size

			$( '.dot-red' ).eq(lastActiveDot).addClass( 'active' );
			$( '.dot-red' ).eq(lastActiveDot).attr('r', '12');
			$( '#circle-titles .title' ).eq(lastActiveDot).addClass( 'active' );

			// Change the scene.
			$( '.scene' ).removeClass( 'active' );
			$( '.scene.scene-' + ( lastActiveDot + 1 ) ).addClass( 'active' );
		}, 1100);

	});




	/**
	 * Move the background circles on mouse over and out.
	 */
	let bgCircle1 = $( '#bg-circle-1' );
	let bgCircle2 = $( '#bg-circle-2' );
	let bgCircle3 = $( '#bg-circle-3' );
	let bgCircle4 = $( '#bg-circle-4' );

	$( '#circle svg' )
		.on( 'mouseover', function(){
			
			TweenMax.to(bgCircle1, 1, {morphSVG:bgCircle3, ease:Power4.ease});
			TweenMax.to(bgCircle2, 1, {morphSVG:bgCircle4, ease:Power4.ease});
			TweenMax.to(bgCircle3, 1, {morphSVG:bgCircle1, ease:Power4.ease});
			TweenMax.to(bgCircle4, 1, {morphSVG:bgCircle2, ease:Power4.ease});


		} )
		.on( 'mouseout', function(){

			TweenMax.to(bgCircle1, 1, {morphSVG:bgCircle1, ease:Power4.ease});
			TweenMax.to(bgCircle2, 1, {morphSVG:bgCircle2, ease:Power4.ease});
			TweenMax.to(bgCircle3, 1, {morphSVG:bgCircle3, ease:Power4.ease});
			TweenMax.to(bgCircle4, 1, {morphSVG:bgCircle4, ease:Power4.ease});

		} );




		/**
		 * INTRO PAGE.
		 * 
		 * Hides the intro page on click of the button and shows the circle page.
		 */
		$( '.intro-page-content .btn' ).on( 'click', function(e){

			e.preventDefault();

			$( '#intro-page' ).addClass( 'page-invisible' );
			$( '#circle-container' ).removeClass( 'page-invisible' );

		} );

});
